<template>
	<div>
		<div class="allcrmbox">
			<div class="leftcrmlist">
			
				<div class="oneicon" v-for="(item,index) in routerList" :key="item.name" @click="choosePath(item,index)">
					<img :src="item.meta.icon" v-if="index !== leftIndex" />
					<img :src="item.meta.activeicon" v-if="index === leftIndex" />
					<span :class="{'activeleft': index === leftIndex}">{{item.meta.text}}</span>
				</div>
			</div>
			<div class="rightcontent"><router-view></router-view></div>
		</div>
	</div>
</template>
<script>
import { userPermissionTree } from '@/network/api.js';
import { mapState,mapMutations } from 'vuex';
export default {
	data() {
		return {
			routerList: [],
			// listindex: 0,
			leftIndex: 0,
		};
	},
	watch: {
		$route() {
			this.getRoutePath()
		}
	},
	name: 'CRM',
	computed: {
		...mapState(['userInfo', 'permissionList', 'isadmin', 'topIndex']),
		...mapMutations(['setTopIndex'])
	},
	activated() {
		this.$store.commit('setTopIndex',1)
		this.getRoutePath()
	},
	created() {
		this._userPermissionTree();
	},
	methods: {
		// 点击侧边栏跳转
		choosePath(item,index) {
			this.leftIndex = index
			this.$router.push({
				path: item.path
			})
		},
		// 获取权限
		async _userPermissionTree() {
			const res = await userPermissionTree();
			console.log('dddddd',res)
			let list = [];
			res.data.forEach(item => {
				list.push(item.permissionName);
				if (item.permissionChildren && item.permissionChildren.length > 0) {
					item.permissionChildren.forEach(item1 => {
						list.push(item1.permissionName);
						if (item1.permissionChildren && item1.permissionChildren.length > 0) {
							item1.permissionChildren.forEach(item2 => {
								list.push(item2.permissionName);
							});
						}
					});
				}
			});
			;
			// 获取一级权限列表
			this.$store.commit('setPermission', list);
			if (res.data.length === 0) {
				this.$store.commit('setAdmin', true);
			}
		
			this.getRouterList();
		},
		// 获取当前路由地址
		getRoutePath() {
			let route = this.$route.path;
			
			this.routerList.forEach((item, index) => {
				if (item.path === route) {
					this.leftIndex = index;
				} else if (item.meta.childrenPath && item.meta.childrenPath.includes(route)) {
					
					this.leftIndex = index;
				} else {
					
					
				}
			});
			
		},
		// 获取路由列表
		getRouterList() {
			if (localStorage.getItem('isStaff') === '0' && this.isadmin) {  /// 如果是总帐号
				this.routerList = this.$router.options.routes
					.filter(item => item.path === '/')[0]
					.children[1].children.filter(item => {
						if (item.meta && item.meta.level === 1) {
							return item;
						}
					});
					
				this.getRoutePath();
				return;
			}
			this.$router.options.routes
				.filter(item => item.path === '/')[0]
				.children[1].children.filter(item => {
					if (item.meta && item.meta.level === 1) {
						if (item.name == 'Home') {
							this.routerList.push(item);
						} else if (this.permissionList.includes(item.name)) {
							this.routerList.push(item);
						}
					}
				});
			this.getRoutePath();
		}
	}
};
</script>

<style scoped lang="scss">
	.oneicon {
		cursor: pointer;
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		margin: 0 0 1.875em 0;
		img {
			width: 1.25em;
			height: 1.25em;
			margin: 0 0 0.3125em 0;
		}
		span {
			font-size: 0.875em;
			font-family: PingFang-SC-Medium, PingFang-SC;
			font-weight: 500;
			color: #5B6271;
			&.activeleft {
				color: #2F79FF !important;
			}
		}
	}
.rightcontent {
	height: calc(100vh - 5em);
	width: calc(100vw - 3.75em);
	overflow-y: scroll;
}
.leftcrmlist {
	padding: 2.125em 0 0;
	background-color: #fff;
	width: 3.75em;
	height: calc(100vh - 5em);
}
.allcrmbox {
	display: flex;
}
@media screen and (max-width: 1920px) {
	.allcrmbox {
		font-size: 16px;
	}
}
@media screen and (max-width: 1720px) {
	.allcrmbox {
		font-size: 15px;
	}
}
@media screen and (max-width: 1520px) {
	.allcrmbox {
		font-size: 14px;
	}
}
@media screen and (max-width: 1320px) {
	.allcrmbox {
		font-size: 13px;
	}
}
@media screen and (max-width: 1120px) {
	.allcrmbox {
		font-size: 12px;
	}
}
</style>
